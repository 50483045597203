module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155264211-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Motoroma website","short_name":"Motoroma","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/favicon-32x32.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/apple-touch-icon.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"995fdd35e03895127fcc2a69dff99058"},
    }]
